  .dropdown-toggle::after {
    content: none;
    }
    .project-listing-div-height {
    min-height: 100vh;
    padding-top: 48px;
    }
    .project-listing-loader-spinner-moon{
    margin-left: 40rem;
    width: 80px;
    height: 80px;
    }
    .project-listing-delete-loader-spinner-moon{
    margin-left: 13rem;
    width: 50px;
    height: 50px;
    }
    .ctn-top-space {
    margin-top: 43px;
    }
    .project-listing-tit-align {
    margin-left: 7px;
    }
    .project-listing-tbl-align {
    width: 1162px;
    margin: auto;
    padding-top: 16px;
    }
    .project-listing-btn-align {
    margin-top: 1rem;
    margin-right: 15px;
    }
    .project-listing-link-dec {
    text-decoration: none;
    }
    .project-listing-link-space {
    display: grid;
    grid-column-gap: 20px 20px;
    grid-row-gap: 10px;
    justify-content: center;
    font-weight: bold;
    }
    .project-listing-project-name-link{
    text-decoration: none;
    color: #212121;
    }
    .project-edit-container .main {
    /* height: 52.5rem; */
    text-align: start;
    padding: 3rem 8%;
    }
    .table>:not(caption)>*>* {
    padding-left: 13px;
    }
    #th-2 {
    padding-left: 33px;
    width: 90px;
    }
    #th-3 {
    padding-left: 23px;
    width: 142px;
    }
    #th-4 {
    padding-left: 16px;
    }
    #th-5 {
    padding-left: 46px;
    }
    .project-listing-no-data-found {
    margin-left: 16px;
    margin-top: 15px;
    }
    .project-container{
    min-height: 865px;
    }
    @media (max-width: 3840px) and (min-width: 1400px) {
    .project-listing-tit-align {
    margin-left: 35px;
    }
    .project-listing-btn-align {
    margin-right: 70px;
    }
    }
    .delete-confirmation-dialog >.modal-dialog>.modal-content
    {
    border-radius: 0rem !important;
    border:none !important;
    }
    
    .delete-confirmation-dialog > .modal-dialog { 
    max-width: 700px;
    padding-top: 0px;
    }
    .delete-confirmation-title {
    margin: 0 auto; 
    width: 540px;
    height: 89px;
    color: #003e7a;
    font-family: "Open Sans";
    font-size: 36px;
    font-style: normal;
    letter-spacing: normal;
    line-height: 44px;
    text-align: left;
    border-radius: 0px;
    }
    .delete-confirmation-body {
    
    padding-top: 0px;
    width: 630px;
    height: 42px;
    color: #888888;
    font-family: "Open Sans";
    font-size: 15px;
    font-style: normal;
    letter-spacing: normal;
    line-height: 22px;
    text-align: left;
    padding-left: 80px;
    }
    
    .delete-confirmation-footer
    {
    margin: 0 auto;
    padding-bottom: 18px;
    border: none !important; 
    }
    .delete-button
    {
    width: 65px;
    height: 24px;
    color: #ffffff;
    font-size: 16px;
    font-weight: 700;
    font-style: normal;
    letter-spacing: 1.3px;
    line-height: 24px;
    text-align: left;
    text-transform: uppercase;
    width: 105px;
    height: 48px;
    background-color: #981b1e !important;
    text-align: center;
    margin-left: 33px;
    border-color:#981b1e !important;
    border-radius: 0px !important;
    }
    .delete-cancel{
    background-color: white !important;
    border-color: white !important;
    color: #aeb0b5 !important;
    font-size: 16px;
    font-weight: 700;
    font-style: normal;
    letter-spacing: 1.3px;
    line-height: 24px;
    text-align: left;
    text-transform: uppercase;
    padding: 0px 0px;
    margin-left: 41px;
    text-decoration: none;
    }