.bg-clr {
    background: #5b9ef4;
    height: 831px;
}

.title-align {
    /* text-align: justify; */
    width: 417px;

   margin-left: 117px;
    margin-top: 150px;
}

.tit-hd {
    font-size: 40px;
    color: #ffffff;
    font-family: 'Open Sans';
    width: 27rem;
}

.tit-p {
    width: 445px;
    height: 46px;
    font-size: 14px;
    height: 46px;
    font-family: 'Open Sans';
    font-size: 16px;
    font-style: normal;
    letter-spacing: normal;
    line-height: 24px;
    text-align: left;
    color: #ffffff;
}

.card {
    margin-left: -12px;
    width: 380px;
    height: 260px;
    /* border-right: none;
    border-left: none;
    border-bottom: none; */
margin-top: 20px;
}

.img-design {
    width: 479px;
    height: 479px;
    border-radius: 50%;
    /* position: absolute; */
}

.img-ht {
    margin-top: 72px;
    flex-direction: row;
    flex-wrap: nowrap;
}

.banner-one {
    margin-left: 5rem;
}

.banner-two {
    margin-left: -19rem;
}

.banner-three {
    margin-left: -16rem;
}

.card-len {
    position: absolute;
    margin-top: -132px;
    margin-left: 38px;
    column-gap: 31px;

}

.Card {
    width: 349px;
    height: 312px;
    background-color: #ffffff;
    margin-left: 165px;
    font-size: 24px;
}

.card-h3 {
    width: 350px;
   
    color: #003e7a;
    font-family: "Open Sans";
    font-size: 24px;
    font-style: normal;
    letter-spacing: normal;
    line-height: 32px;
    text-align: left;
    padding: 1px;
    margin-left: 2px;
    margin-top: 0px;

}

.card1-h3 {
    width: 309px;
    height: 126px;
    color: #003e7a;
    font-family: "Open Sans";
    font-size: 24px;
    font-style: normal;
    letter-spacing: normal;
    line-height: 32px;
    text-align: left;
    padding: 22px;
    margin-left: -16px;
}


.card2-h3 {
    width: 345px;
    height: 95px;
    color: #003e7a;
    font-family: "Open Sans";
    font-size: 24px;
    font-style: normal;
    letter-spacing: normal;
    line-height: 32px;
    text-align: left;
    padding: 28px;
    margin-left: -25px;
    margin-top: -7px;
}

.btn-gt {
    width: 184px;
    height: 48px;
    background-color: #003e7a;
    float: left;
    margin-top: 23px;
    text-align: center;
}

.banner-div {
    height: 831px;
    overflow: hidden;
}

.card-con {
    /* background: #ffffff;
    height: 410px; */
    display: flex;
    flex-direction: row;
    flex: 1; 
}

.card-p {
    width: 309px;
    height: 118px;
    color: #888888;
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    letter-spacing: normal;
    line-height: 22px;
    text-align: left;
    padding: 0px;
    margin-left: 2.5px;
    margin-top: 23px;
}

.card-p1 {
    width: 309px;
    color: #888888;
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    letter-spacing: normal;
    line-height: 22px;
    text-align: left;
    padding: 0px;
    margin-left: 5px;
    margin-top: 41px
}

.card-p2 {
    width: 309px;
    color: #888888;
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    letter-spacing: normal;
    line-height: 22px;
    text-align: left;
    padding: 0px;
    margin-left: 4px;
    margin-top: 53px;
}

.col-border {
    border-top: 9px solid #003e7a;

}

/* .card-shadow {
    box-shadow: 0 39px 30px rgb(35 35 36 / 13%);
} */

.btn-bottom {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
}

.btn-brow {
    text-decoration: none;
    color:#aeb0b5;
    font-size: 16px;
    font-weight: 700;
    font-style: normal;
    letter-spacing: 1.3px;
    line-height: 24px;
    text-align: left;
    text-transform: uppercase;
    font-family: 'Open Sans';
    font-weight: bold;
    cursor: pointer;
}

.arrow-align {
    margin-bottom: 2px;
}


.test:hover .btn-hra-primary {
    color: black;

}

  
 .home-ht-len{
    height: 75vmax;
 }
 

.ht-len
{
   height: 215px; 
}

.btn-div{
    justify-content: center;
    align-items: center;
    padding-bottom: 72px
}

@media (max-width: 1366px){
    .ht-len  {
        height: 175px;

      }
      .card {
        margin-left: -12px;
        width: 360px;
        height: 260px;
        margin-top: -11px;
    }
    .card-len{
        margin-left: -20px;
    }
 }
 @media (max-width: 1399px)and (min-width:1367px){
    .ht-len  {
        height: 175px;

      }
      .card {
        margin-left: -12px;
        width: 360px;
        height: 260px;
        margin-top: -11px;
    }
    .card-len{
        margin-left: -20px;
    }
 }
 
 @media (max-width: 1280px){
    .ht-len {
        height: 210px;

      }
      .card {
        margin-left: -24px;
        width: 338px;
    }
    .card-len{
        margin-left: 31px  !important;
    }
 }
 @media (max-width:1199px)and (min-width:1024px){
    .ht-len {
        height: 630px;

      }
     .title-align{
     margin-left: 133px;
     }
     .card-col {
        flex: 1 0 !important;
        margin-left:38px;
        margin-top: 10%;
    }
     
 }

@media (max-width: 983px){
    .ht-len  {
        height:  796px;
    }
 }

 
 

 
