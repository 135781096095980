.eligible-program-container-btn-eligible-pad {
  width: 127px;
  height: 25px;
  border-radius: 4px;
  background-color: #d6d7da;
}

.eligible-program-container-btn-style {
  width: 101px;
  height: 28px;
  color: #323a44;
  font-size: 12px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: normal;
  line-height: 20px;
  text-align: center;
  padding-top: 4px;
}

/* .eligible {
  margin-left: 164px;
} */

.row-hover:hover {
  background: red;
}

.layout-main.eligible {
  overflow: auto;
  min-height: 100vh;
  margin: auto;
}

.eligible-programs-heading {
  padding-top: 100px;
  margin-left: -11px;
}

.eligible-programs-btnsign {
  width: 208px;
  height: 48px;
}

.eligible-programs-subheading {
  padding-top: 92px;
}

.eligible-program-container-btn-style-1 {
  width: 101px;
  height: 28px;
  color: #323a44;
  font-size: 12px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: normal;
  line-height: 20px;
  text-align: center;
  padding-top: 4px;
  float: left;
  margin-left: 18px;
}

.eligible-program-container-tbl-design {
  width: 1110px;
  margin-top: 25px;
}

.eligibility-program-table-head-style {
  border-color: #003e7a;
  height: 40px;
  background-color: #003e7a;
}

table#all-programs-table tbody:nth-child(odd) {
  background-color: #f8f8f8;
}

table#eligible-programs-table tbody:nth-child(odd) {
  background-color: #f8f8f8;
}

table#all-programs-table tbody {
  border: 1px solid #f2f2f2;
}

.eligible-program-programname {
  width: 354px;
  height: 16px;
  color: #ffffff;
  font-size: 12px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  text-align: left;
}

.eligible-program-typeofproject {
  width: 136px;
  height: 16px;
  color: #ffffff;
  font-size: 12px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  text-align: left;
}

.eligible-program-projectcategory {
  width: 141px;
  height: 16px;
  color: #ffffff;
  font-size: 12px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  text-align: left;
}

.eligible-program-amount {
  width: 146px;
  height: 16px;
  color: #ffffff;
  font-size: 12px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  text-align: right;
}

.eligible-program-eligibility {
  width: fit-content;
  height: 16px;
  color: #ffffff;
  font-size: 12px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  text-align: left;
  padding-left: 30px !important;
}

.eligible-container-btn-style {
  width: 99px;
  height: 20px;
  color: #323a44;
  font-size: 12px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: normal;
  line-height: 20px;
  text-align: center;
  padding-top: 5px;
  padding-bottom: 5px;
}

.eligible-program-tb-body {
  height: 78px;
  vertical-align: middle;
  border-color: white;
}

.eligible-program-tb-body:hover {
  background-color: #e5e8f0;
}

.ep-name {
  /* width: 316px; */
  height: 19px;
  color: #212121;
  font-size: 14px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  text-align: left;
}

.ep-body-pc {
  /* width: 110px; */
  height: 36px;
  color: #323a44;

  font-size: 12px;
  font-style: normal;
  letter-spacing: normal;
  line-height: 20px;
  text-align: left;
}

.ep-body-top {
  /* width: 110px; */
  height: 37px;
  color: #323a44;
  font-size: 12px;
  font-style: normal;
  letter-spacing: normal;
  line-height: 20px;
  text-align: left;
}

.ep-body-amt {
  width: 90px;
  height: 20px;
  color: #323a44;
  font-size: 12px;
  font-style: normal;
  letter-spacing: normal;
  line-height: 20px;
  text-align: right;
}

.ep-body-eligible {
  width: fit-content;
  display: flex;
  height: 100%;
  align-items: center;
  height: 78px;
  border-bottom-width: 0px !important;
  padding-left: 30px !important;
}

.eligible-program-disclaimer-drop-down {
  width: 256px;
  height: 58px;
  color: #323a44;
  font-size: 12px;
  font-style: normal;
  letter-spacing: normal;
  line-height: 20px;
  text-align: left;
}
.div-hover:hover {
  background-color: #f8f8f8;
}
.recipients {
  padding: 20px 0px 0px 30px;
}

.eligibility-program-non-elgible-btn {
  width: 100px;
  height: 25px;
  border-radius: 4px;
  background-color: #aeb0b5;
  opacity: 0.5;
}

.eligibility-program-non-elgible-letter {
  width: 100px;
  color: #323a44 !important;
  font-weight: bold;
  font-size: 12px;
  font-style: normal;
  letter-spacing: normal;
  line-height: 20px;
  text-align: left;
  padding-top: 2px;
}

.eligible-program-recipients {
  /* width: 112px; */
  height: 20px;
  color: #323a44;
  font-size: 12px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: normal;
  line-height: 20px;
  text-align: left;
  margin-top: 15px;
}

.eligible-program-recipients-drop-down {
  color: #323a44;
  font-size: 12px;
  font-style: normal;
  letter-spacing: normal;
  line-height: 20px;
  text-align: left;
}

.eligible-program-disclaimer {
  width: 67px;
  height: 20px;
  color: #323a44;
  font-size: 12px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: normal;
  line-height: 20px;
  text-align: left;
}

.two-layer-icon {
  width: 20px;
  margin-right: 5px;
}

.eligible-program-purpose {
  width: 53px;
  height: 20px;
  color: #323a44;
  font-size: 12px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: normal;
  line-height: 20px;
  text-align: left;
}

.eligible-program-purpose-drop-body {
  color: #323a44;
  font-size: 12px;
  font-style: normal;
  letter-spacing: normal;
  line-height: 20px;
  text-align: left;
}

.eligible-program-drp-down,
.eligible-program-drp-up {
  margin-right: 30px;
  width: 27px;
  height: 27px;
  float: right;
}

#all-programs-table
  > tbody
  > tr.eligible-program-tb-body.collapsed
  > td
  > svg.eligible-program-drp-up,
#eligible-programs-table
  > tbody
  > tr.eligible-program-tb-body.collapsed
  > td
  > svg.eligible-program-drp-up {
  display: none;
}

#all-programs-table
  > tbody
  > tr.eligible-program-tb-body:not(.collapsed)
  > td
  > svg.eligible-program-drp-up,
#eligible-programs-table
  > tbody
  > tr.eligible-program-tb-body:not(.collapsed)
  > td
  > svg.eligible-program-drp-up {
  display: block;
}

#all-programs-table
  > tbody
  > tr.eligible-program-tb-body.collapsed
  > td
  > svg.eligible-program-drp-down,
#eligible-programs-table
  > tbody
  > tr.eligible-program-tb-body.collapsed
  > td
  > svg.eligible-program-drp-down {
  display: block;
}

#all-programs-table
  > tbody
  > tr.eligible-program-tb-body:not(.collapsed)
  > td
  > svg.eligible-program-drp-down,
#eligible-programs-table
  > tbody
  > tr.eligible-program-tb-body:not(.collapsed)
  > td
  > svg.eligible-program-drp-down {
  display: none;
}

.table > thead {
  vertical-align: unset;
}

.title-head {
  padding-left: 0px;
  margin-bottom: 4px;
}

.title-body-main {
  padding-left: 0px;
}

.title-body {
  padding-left: 0px;
}

.popover {
  margin-left: -10px;
  box-shadow: 0px 0px 8px rgb(158, 153, 153);
  max-width: 368px;
  font-size: 12px;
  font-family: 'Open Sans';
  border-radius: 0rem !important;
}

.popover .popover-arrow::after {
  margin-left: 7px;
}
.sort-data {
  height: 17px;
  width: 17px;
  cursor: Pointer;
}
.sort-data:hover {
  transform: scale(1.2);
}

#all-programs-table > thead > tr > th.eligible-program-typeofproject,
#all-programs-table > thead > tr > td.ep-body-pc {
  width: 205px;
}
#all-programs-table > thead > tr > th.eligible-program-projectcategory,
#all-programs-table > thead > tr > th.ep-body-top {
  width: 192px;
}

#all-programs-table > thead > tr > th.eligible-program-programname,
#all-programs-table > thead > tr > td.ep-name {
  width: 420px;
}
.eligibility-container-btn-learn {
  float: left;
  margin-top: 5px;
  padding-top: 11px;
}
.eligibility-container-accordian {
  height: 376px !important;
}

.eligible-description {
  color: #888888;
  font-family: 'Open Sans';
  font-size: 14px !important;
  font-style: normal;
  letter-spacing: normal;
  line-height: 22px;
  text-align: left;
}

.eligible-content{
  padding: 0px;
}
