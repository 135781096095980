.hd-navs-bar-wd-align {
  position: fixed;
  width: 100%;
  max-width: 1440px;
  background-color: #ffffff;
}

.header-navbar-loginalign {
  padding-left: 20px !important;
  margin-left: 4px;
}

a.nav-link {
  margin-right: 13px;
}

div#basic-navbar-nav {
  height: 41px;

}

img.d-inline-block.align-top {
 height: 64px;
 width: auto;
    padding-left: 25px;
}
.beta{
  padding-top: 2px;
  font-weight: 600;
    letter-spacing: 1px;
    color:#888888;
}
a.app-login.header-navbar-loginalign.nav-link {
  margin-right: 3px;
  margin-inline-start: -7px;
}

a.nav-link {
  font-family: "Open Sans";
  margin: 0 2px;
  letter-spacing: 1.13px;
  text-align: left;
  font-size: 12px;
  margin-inline-end: 4px;
}

nav.navbar.navbar-expand-lg.navbar-light.bg-light.sticky-top {
  height: 44px;
}

.ms-auto.justify-content-end.navbar-nav {
  margin-right: 4px;
}

@media (max-width: 1025px) and (min-width: 370px) {
  .navbar-nav{
   display: none; 
  }
}
