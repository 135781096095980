.sidebar-group-text {
  text-transform: uppercase;
  color: #003e7a;
  font-size: 0.9rem;
  font-weight: bold;
  letter-spacing: 1px;
  font-weight: bold;
  padding-left: 31px;
  padding-top: 30px;
  width: 100%
}

.layout-sidebar {
  width: 26%;
  background: #f0efef;
  /* padding-left:2em; */
}

.layout-sidebar .main {
  text-align: start;
  padding: 3rem 6rem;
}

.layout-sidebar .main p {
  opacity: 1;
  font-size: 13px;
}

.layout-sidebar .main h2 {
  margin-bottom: 0px;
}

.layout-sidebar .form-select {
  padding: 12px !important;
  margin-bottom: 5px !important;
}

.layout-sidebar .form-control {
  padding: 12px !important;
  margin-bottom: 30px !important;
}

.layout-sidebar .add-btn {
  float: right;
  margin-top: 30px !important;
  width: 140px !important;
}

.layout-sidebar .nav-bar {
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: nowrap;
  text-align: start;
  margin-top: 30px;

}

.layout-sidebar .nav-bar::after {
  content: "";
  margin-left: 18px;
  margin-top: 25px;
  height: 1px;
  width: calc(100% - 30px);
  background-color: rgba(180, 180, 180, 0.3);
}

.layout-sidebar .nav-link {
  min-height: 54px;
  /* height: 54px; */
  padding: 0%;
  color: #323a44;
  font-family: "Open Sans";
  font-size: 14px;
  font-style: normal;
  letter-spacing: normal;
  line-height: 22px;
  text-align: left;
}

.layout-sidebar .nav-item {
  color: #323a44;
  cursor: pointer;
}

.layout-sidebar>ul>li>a:hover {
  background-color: white;
}

.layout-sidebar>ul>li>div.show>div.nav-item a:hover {
  background-color: white;
}
.layout-sidebar>ul>li>div.show>div.nav-item a.active {
  background-color: white;
}

.layout-sidebar .width {
  width: 100% !important
}

.layout-sidebar .nav-side-link {
  padding: 16px 32px !important;
  color: #323a44;
}

.nav-side-link.nav-indent-2.nav-link {
  padding-left: 50px !important;
  ;
}

.layout-sidebar .nav-link a {
  padding-left: 35px;
  color: #323a44;
  font-family: "Open Sans";
  font-size: 14px;
  letter-spacing: normal;
  margin-top: 10px;

}

.layout-sidebar .navbar-text {
  /* margin-left: 30px; */
  margin-left: 2rem;
  margin-top: 30px;
  color: #003E7A;
  font-size: 12px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: 1.2px;
  line-height: 30px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
.layout-sidebar .menu-group-container {
  padding-left: 20px;
}

.layout-sidebar .nav-link :hover .seq-number :not(.show) {
  color: #5b9ef4;
}

.layout-sidebar .nav-link :hover {
  color: #003E7A !important;
}

.layout-sidebar .seq-number {
  padding-right: 5px;
  color: #c4c4d0;
  font-size: 12px;
}

.answer-ratio {
  float: right;
  color: #5b9ef4;
  margin-top: 5px;
  font-size: 12px;
}
.layout-sidebar .nav-link.disabled {
  color: darkgray;
}
.side-menu-intro{
  margin-left: 8px;
}