.arrange-horizontally > * {
    display: inline-block;
    text-align: center;
}
/* .arrange-horizontally :nth-child(1) { 
    padding-left: 30px;
} */
.arrange-horizontally  > :first-child { margin-top: 0 !important; margin-left: 0 !important; }
.arrange-horizontally  > :last-child { margin-bottom: 0 !important; margin-right: 0 !important; }
/* .sc-card-ht{
    margin-top: 65px;
} */
.single-doughnut {
    /* height: 170px; */
    /* width: 170px; */
    margin-left: 9px;
    /* padding-right: 30px; */
}
.label-top {
    transform: translateY(3px);
    font-size: 37px;
    font-family: 'Open Sans';
    font-weight: 600;
    padding-top:5px;
    color: #5b616a;
}
.label-text {
    transform: translateY(11px);
    font-size: 13px;
    font-family: 'Open Sans';
    font-weight: normal;
    color: #5b616a;
    line-height: 24.42px;

}
.btn-align-width{
   width: 352px;
   text-align: center;
  margin: auto;
}

.sc--hide-btn{
    margin-top: 35px
}
.score-card-errormsg{
    color: red;
    font-family: "Open Sans";
    font-size: 14px !important;
    font-style: normal;
    letter-spacing: normal;
    line-height: 22px;
}